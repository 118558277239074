import store from "../store";
import moment from 'moment';

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType,
    token: store.getters.getMerchantData.merchant_access_token,
  }
  return { headers }
}

export default axios => ({
  async getOrderList(type, limit, offset, query, pickup_id, late_mark) {
    try {
      const obj = {
        ...(type ? { type } : {}),
        limit, offset, query, pickup_id,
        ...(late_mark ? { late_mark } : {}
      )}

      return await axios.get(process.env.VUE_APP_API_URL
        + (store.getters.getMerchantData.merchant_pick_up_point_platform.name == 'OWN' ? 'merchants' : 'external') + '/order/lists?'
        + Object.keys(obj).reduce((sum, key) => {
          if (obj[key] || parseInt(obj[key]) >= 0) sum.push(`${key}=${obj[key]}`)
          return sum
        }, []).join('&'), headers())
    } catch (e) {
      return e.response      
    }
  },
  async getOrderListCount(params) {
    try {
      const isOwnMerchant = store.getters.getMerchantData.merchant_pick_up_point_platform.name == "OWN"
      return await axios.get(process.env.VUE_APP_API_URL + `${isOwnMerchant ? 'merchants' : 'external'}/order/total_list`, {
        params,
        ...headers()
      })
    } catch (e) {
      return e.response
    }
  },
  async getOrderDetail(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/order/detail/' + id, headers())
    } catch (e) {
      return e.response      
    }
  },
  async updateStatus(id, status, no) {
    try {
      const endpoint = store.getters.getMerchantData.merchant_pick_up_point_platform.name == 'OWN'
        ? 'merchants/order/update/'
        : 'external/order/ready_pick_up/'

      const body = { status }
      if (no) body.tracking_number = no
      return await axios.patch(process.env.VUE_APP_API_URL + endpoint + id, body, headers())
    } catch (e) {
      return e.response
    }
  },
  async downloadInvoice(id) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/order/download_invoice/' + id, {}, headers())
    } catch (e) {
      return e.response  
    }
  },
  async trackOrder(order) {
    try {
      const body = {
        waybill: order.tracking_number,
        courier: order.courier_code,
      }
      const res = await axios.post(process.env.VUE_APP_API_URL + 'merchants/order/track_order', body, headers())
      return res.data.data || {
        manifest: [{
          manifest_date: "",
          manifest_time: "",
          manifest_description: "Sorry, shipment tracking data not found",
        }]
      }
    } catch (e) {
      return e.response
    }
  },
  async validatePickupCode(id, pickup_code) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL
        + (store.getters.getMerchantData.merchant_pick_up_point_platform.name == 'OWN' ? 'merchants' : 'external') + '/order/validate_pickup_code', {id, pickup_code}, headers())
    } catch (e) {
      return e.response
    }
  },
  async addCourierInfo(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + 'merchants/order/pickup_info', data, headers('multipart/form-data'))
    } catch (e) {
      return e.response
    }
  },
  async updateCourierInfo(data) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/order/pickup_info', data, headers('multipart/form-data'))
    } catch (e) {
      return e.response
    }
  },
  async requestJnePickup(data) {
    try {
      const merchant = store.getters.getMerchantData;
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/order/update_pickup_res/' + data.id, {
        "PICKUP_NAME" : "Whizliz",
        "PICKUP_DATE" : moment(Date.now()).format('DD-MM-YYYY'),
        "PICKUP_TIME" : moment(Date.now()).format('HH:mm'),
        "PICKUP_PIC" : "WINDY",
        "PICKUP_PIC_PHONE" : merchant.merchant_phone.replace(/^\+62/, '0'),
        "PICKUP_ADDRESS" : merchant.address,
        "PICKUP_DISTRICT" : merchant.merchant_township.name,
        "PICKUP_CITY" : merchant.merchant_district.name,
        "PICKUP_SERVICE" : data.courier_service_code,
        "PICKUP_VEHICLE" : "Motor",
        "SPECIAL_INS": "Hati Hati",
        "WEIGHT": 1,
        "status": 2
      }, headers())
    } catch (e) {
      return e.response;
    }
  }
})
