<template>
  <div class="position-fixed bottom-0 end-0 mr-3 mb-2" style="z-index: 99">
    <router-link
      to="/livechat"
      class="d-flex align-items-center bg-black rounded-pill p-2"
    >
      <img src="@/assets/icon/iconchat.png" />
      <b class="pl-2 text-lg text-white montserrat">LizChat</b>
    </router-link>
    <span class="livechat-badge" v-if="totalUnreadCounter">{{
      totalUnreadCounter
    }}</span>
  </div>
</template>

<script>
export default {
  computed: {
    dialogList() {
      return this.$store.getters.getDialogList;
    },
    totalUnreadCounter: function () {
      let total = 0;
      for (const val of Object.values(this.dialogList)) {
        total = total + val.unread_messages_count;
      }
      return total;
    },
  },
};
</script>