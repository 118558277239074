import store from "../store";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType,
    token: store.getters.getMerchantData.merchant_access_token,
  }
  return { headers }
}

export default axios => ({
  async getProductDetail(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/products/' + id, headers())
    } catch (e) {
      return e.response
    }
  },
  async getProductList(type, limit, offset, query) {
    try {
      const obj = {type, limit, offset}
      if (query) obj.query = query
      let queryString = []
      for (let o in obj){
        queryString.push(o + '=' + obj[o])
      }
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/products/lists?' + queryString.join('&'), headers())
    } catch (e) {
      return e.response      
    }
  },
  async getProductsByBrand(limit, offset, query, brandId, status_purchaseable, productType) {
    try {
      let filter = {
        logic: "and",
        filters: [
          {
            property: "brand.id",
            operator: "in",
            values: (() => {
              const retval = []
              for (const id in brandId)
                retval.push({value: brandId[id]})
              return retval
            })()
          }
        ],
      }
      if (query) filter.filters.push({
        property: "name",
        operator: "like",
        values: [{ value: query}]
      })
      filter = encodeURIComponent(JSON.stringify(filter))
      const sort = encodeURIComponent(JSON.stringify([{
        property: "slug",
        direction: "asc",
      }]))
      const obj = {limit, offset, filter, sort}
      if (productType) obj.product_type = productType
      if (status_purchaseable !== null) obj.status_purchaseable = status_purchaseable
      const queryString = []
      for (const o in obj)
        queryString.push(o + '=' + obj[o])
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/products/search?' + queryString.join('&'), headers())
    } catch (e) {
      return e.response
    }
  },
  async getUserProduct(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/products/search_detail/' + id, headers())
    } catch (e) {
      return e.response
    }
  },
  async getProductVariance(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/products/detail/lists/' + id, headers())
    } catch (e) {
      return e.response
    }
  },
  async bulkEditStock(ids, stock, relative) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/products/update_stock_bulk', {
        product_detail_variance_ids: ids,
        stock,
        stock_relative: relative,
      }, headers())
    } catch (e) {
      return e.response;
    }
  },
  async bulkEditProduct(data, params) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/products/update_price_bulk', data, {
        params,
        ...headers()
      });
    } catch (e) {
      return e.response;
    }
  },
  async saveMerchantReply(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + 'merchants/productreview/reply', data, headers())
    } catch (e) {
      return e.response
    }
  },
  async deleteMerchantReply(id) {
    try {
      return await axios.delete(process.env.VUE_APP_API_URL + 'merchants/productreview/reply/delete/' + id, headers())
    } catch (e) {
      return e.response
    }
  },
  async getProductReview(status, limit, offset, title, start, end) {
    try {
      const obj = {status, limit, offset}
      if (title) obj.title = title
      if (start) obj.start = start
      if (end) obj.end = end
      let queryString = []
      for (let o in obj){
        queryString.push(o + '=' + obj[o])
      }
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/productreview/list?' + queryString.join('&'), headers())
    } catch (e) {
      return e.response      
    }
  },
  async setActive(active, id) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/products/' + id + '/update_status', {active}, headers())
    } catch (e) {
      return e.response
    }
  },
  async saveProduct(body) {
    try {
      const data = JSON.parse(Object.fromEntries(body.entries()).data)
      if (data.id) return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/products/update_json', body, headers('multipart/form-data'))
      else return await axios.post(process.env.VUE_APP_API_URL + 'merchants/products/save_json', body, headers('multipart/form-data'))
    } catch (e) {
      return e.response
    }
  },
  async saveVariant(body) {
    try {
      if (body.id) return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/products/detail/update', body, headers())
      else return await axios.post(process.env.VUE_APP_API_URL + 'merchants/products/detail/save', body, headers())
    } catch (e) {
      return e.response      
    }
  },
  async deleteProduct(id) {
    try {
      return await axios.delete(process.env.VUE_APP_API_URL + 'merchants/products/delete/' + id, headers())
    } catch (e) {
      return e.response      
    }
  },
  async deleteVariant(id) {
    try {
      return await axios.delete(process.env.VUE_APP_API_URL + 'merchants/products/detail/delete/' + id, headers())
    } catch (e) {
      return e.response      
    }
  },
  async getAttributeDetail(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/products/attributes/' + id, headers())
    } catch (e) {
      return e.response
    }
  },
  async getAttributeList() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/products/attributes', headers())
    } catch (e) {
      return e.response
    }
  },
  async getCategorySize() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/productsize', headers())
    } catch (e) {
      return e.response
    }
  },
  async saveCategorySize(body) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + 'merchants/productsize/bulk', body, headers())
    } catch (e) {
      return e.response
    }
  },
  async updateCategorySize(body) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + '/merchants/productsize/bulk/edit', body, headers())
    } catch (e) {
      return e.response
    }
  },
  async deleteCategorySize(id) {
    try {
      return await axios.delete(process.env.VUE_APP_API_URL + `/merchants/productsize/delete/${id}`, headers())
    } catch (e) {
      return e.response
    }
  },
  async getProductSizeCategories() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/productsize/category', headers())
    } catch (e) {
      return e.response
    }
  },
})
