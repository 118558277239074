import store from "../store";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType,
    token: store.getters.getMerchantData.merchant_access_token,
  }
  return {
    headers
  }
}

export default axios => ({
  async getPickupPointList() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/pickup/list', headers())
    } catch (e) {
      return e.response
    }
  },
  async getPickupPointDetail(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/pickup/get/' + id, headers())
    } catch (e) {
      return e.response
    }
  },
  async savePickupPoint(body, id) {
    try {
      if (id) return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/pickup/edit/' + id, body, headers())
      else return await axios.post(process.env.VUE_APP_API_URL + 'merchants/pickup/add', body, headers())
    } catch (e) {
      return e.response
    }
  },
  async deletePickupPoint(id) {
    try {
      return await axios.delete(process.env.VUE_APP_API_URL + 'merchants/pickup/delete/' + id, headers())
    } catch (e) {
      return e.response;
    }
  }
})
