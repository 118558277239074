import store from "../store";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  const token = store.getters.getMerchantData.merchant_access_token
  if (token) headers.token = token
  return { headers }
}

export default axios => ({
  async addBundle(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + 'merchants/bundle', data, headers())
    } catch (e) {
      return e.response
    }
  },
  async editBundle(data) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/bundle', data, headers())
    } catch (e) {
      return e.response
    }
  },
  async editBundleStock(id, quantity) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/bundle/stock/' + id, {quantity}, headers())
    } catch (e) {
      return e.response
    }
  },
  async deleteBundle(id) {
    try {
      return await axios.delete(process.env.VUE_APP_API_URL + 'merchants/bundle/delete/' + id, headers())
    } catch (e) {
      return e.response
    }
  },
  async getBundles(limit, offset, query) {
    try {
      const obj = {limit, offset}
      if (query) obj.query = query
      const queryString = []
      for (const o in obj)
        queryString.push(o + "=" + obj[o])
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/bundle?' + queryString.join('&'), headers())
    } catch (e) {
      return e.response
    }
  },
  async getOneBundle(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/bundle/view/' + id, headers())
    } catch (e) {
      return e.response
    }
  },
  async getBundleItems() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/bundle/items', headers())
    } catch (e) {
      return e.response
    }
  }
})
