<template>
  <div class="container">
    <div class="text-center">
      <router-link to="/my-profile">
        <img src="@/assets/img/person-1.png" class="border rounded-circle mb-3" />
      </router-link>
      <div class="mb-2">
        <p class="text-link m-0"><b><router-link to="/my-profile">{{ merchantData.merchant_full_name }}</router-link></b></p>
        <p class="text-grey m-0">#{{ merchantData.merchant_id }}</p>
      </div>
      <!-- <div class="text-sm">
        <router-link class="d-flex justify-content-center align-items-center text-black" to="/my-profile">
          <i class="icon-edit mr-1"></i>
          Edit Profile
        </router-link>
        <router-link class="d-flex justify-content-center align-items-center text-black mt-1" to="/change-password">
          <i class="icon-edit mr-1"></i>
          Edit Password
        </router-link>
      </div> -->
      <p class="text-lg my-4" style="line-height: normal;">
        <router-link class="text-black" to="/order-lists">
          <b>{{ trxCount }}</b><br/>
          <span class="text-link">Transaction</span>
        </router-link>
      </p>
    </div>
    <hr />
    <ul class="sidebar">
      <template v-for="(nav, i) in navigations.filter(el => !el.disabled)">
        <li v-if="nav.child.length == 0" :key="i">
          <router-link exact :to="nav.to" :title="nav.label">{{ nav.label }}</router-link>
        </li>
        <template v-else>
          <li v-b-toggle="'collapse-' + nav.label.split(' ')[0].toLowerCase()" :key="'parent' + i">
            <span>{{ nav.label }}</span>
          </li>
          <b-collapse :id="'collapse-' + nav.label.split(' ')[0].toLowerCase()" :key="i">
            <li v-for="(navChild, j) in nav.child.filter(el => !el.disabled)" :key="j">
              <router-link exact :to="navChild.to" :title="navChild.label">{{ navChild.label }}</router-link>
            </li>
          </b-collapse>
        </template>
      </template>
      <li>
        <router-link to="/logout" class="d-md-none">Logout</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
export default {
  name: "Sidebar",
  data() {
    return {
      trxCount: 0,
      navigations: [],
    }
  },
  computed: {
    merchantData() {
      return this.$store.getters.getMerchantData
    }
  },
  mounted() {
    this.generateMenu()
    this.getTrxCount()
  },
  methods: {
    generateMenu() {
      this.navigations = [
        {
          disabled: this.merchantData.merchant_pick_up_point_platform.name != 'OWN',
          label: "Dashboard",
          to: "/",
          child: [],
        },
        // {
        //   disabled: false,
        //   label: "Offline Store Viewing",
        //   to: "/appointment",
        //   child: []
        // },
        {
          disabled: this.merchantData.merchant_pick_up_point_platform.name != 'OWN',
          label: "Brands",
          to: "/manage-brand",
          child: [],
        },
        {
          disabled: this.merchantData.merchant_pick_up_point_platform.name != 'OWN',
          label: "Bundling",
          to: null,
          child: [
            {
              label: "Add Bundle",
              to: "/add-bundle",
            },
            {
              label: "Manage Bundle",
              to: "/manage-bundle",
            },
          ],
        },
        {
          disabled: this.merchantData.merchant_pick_up_point_platform.name != 'OWN',
          label: "Products",
          to: null,
          child: [
            {
              label: "Add Products",
              to: "/add-product",
            },
            {
              label: "Manage Products",
              to: "/manage-product",
            },
            {
              label: "Products Review",
              to: "/review-product",
            },
            {
              label: "Size Guide",
              to: "/manage-size-guide"
            },
          ],
        },
        {
          disabled: this.merchantData.merchant_pick_up_point_platform.name != 'OWN',
          label: "Collection",
          to: null,
          child: [
            {
              label: "Add Collection",
              to: "/add-collection",
            },
            {
              label: "Manage Collection",
              to: "/manage-collection",
            },
          ],
        },
        {
          disabled: false,
          label: "Orders",
          to: null,
          child: [
            {
              label: "All Orders",
              to: "/order-list",
            },
            {
              label: "New Order",
              to: "/order-list?t=new",
            },
            {
              label: "Processed",
              to: "/order-list?t=paid",
            },
            {
              label: "On Delivery",
              to: "/order-list?t=packed",
            },
            {
              label: "Delivered",
              to: "/order-list?t=delivered",
            },
            {
              label: "Cancelled",
              to: "/order-list?t=cancelled",
            },
          ],
        },
        {
          disabled: false,
          label: "Late Orders",
          to: "/late-order",
          child: [],
        },
        {
          disabled: false,
          label: "Settings",
          to: null,
          child: [
            {
              label: "Account Information",
              to: "/my-profile",
            },
            {
              label: "Security Setting",
              to: "/change-password",
            },
            {
              disabled: this.merchantData.merchant_pick_up_point_platform.name != 'OWN',
              label: "Store Status",
              to: "/manage-store",
            },
            {
              disabled: this.merchantData.merchant_pick_up_point_platform.name != 'OWN',
              label: "Pick-up Point",
              to: "/list-pickup",
            },
          ],
        },
        {
          disabled: this.merchantData.merchant_pick_up_point_platform.name != 'OWN',
          label: "Reports",
          to: null,
          child: [
            {
              label: "Order Report",
              to: "/order-report",
            },
            {
              label: "Top Sales Report",
              to: "/top-sales-report",
            },
          ],
        },
      ]
    },
    async getTrxCount() {
      try {
        const res = await this.$api.order.getOrderListCount()
        if (res.status === 200) this.trxCount = res.data.data.total_all;
        else toaster.make('Something went wrong', 'danger')
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>