/* eslint-disable no-console */

import { register } from 'register-service-worker'

register(`${process.env.BASE_URL}${process.env.VUE_APP_API_URL.indexOf('staging') < 0 ? 'firebase-messaging' : 'fcm'}-sw.js`, {
  ready (registration) {
    console.log(registration, 'Service worker is active.')
  },
  registered (registration) {
    console.log(registration, 'Service worker has been registered.')
  },
  cached (registration) {
    console.log(registration, 'Content has been cached for offline use.')
  },
  updatefound (registration) {
    console.log(registration, 'New content is downloading.')
  },
  updated (registration) {
    console.log(registration, 'New content is available; please refresh.')
  },
  offline () {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error (error) {
    console.error('Error during service worker registration:', error)
  }
})