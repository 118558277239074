<template>
  <section class="content">
    <Header />
    <div class="container container-fhd">
      <div class="row no-gutters my-3">
        <div 
          v-if="!isFaqPage"
          class="col-2 d-none d-md-block"
          :class="{'mw-32pt': hideMenu}"
        >
          <div class="py-2 shadow rounded text-nowrap">
            <div class="container" :class="{'mb-2': !hideMenu}">
              <a href="#" @click="hideMenu = !hideMenu">
                <fawesome-pro variant="fad" :icon="`${!hideMenu ? 'minus' : 'plus'}-square`" class="pr-1" title="Toggle Menu" />
                <small :class="{'d-none': hideMenu}">{{ !hideMenu ? 'Hide' : 'Show'}} Menu</small>
              </a>
            </div>
            <Sidebar
              :class="{'d-none': hideMenu}"
            />
          </div>
        </div>
        <div class="col">
          <router-view />
        </div>
      </div>
    </div>
    <Livechat v-if="merchantData.merchant_pick_up_point_platform.name == 'OWN'" />
    <Footer />
  </section>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/navs/Header";
import Livechat from "@/components/livechat/Livechat";
import Sidebar from "@/components/navs/Sidebar";
import Footer from "@/components/navs/Footer";

import { qbHelper, toaster } from "@/_helpers";
import QB from "quickblox/quickblox.min";
import moment from "moment";

export default {
  name: "Home",
  components: {
    Header,
    Sidebar,
    Livechat,
    Footer,
  },
  data() {
    return {
      hideMenu: false,
    };
  },
  computed: {
    merchantData() {
      return this.$store.getters.getMerchantData;
    },
    isFaqPage() {
      return this.$route.path.indexOf("faq") != -1;
    },
    dialogList() {
      return this.$store.getters.getDialogList;
    },
    messageList() {
      return this.$store.getters.getMessageList;
    },
    currentDialog() {
      return this.$store.getters.getCurrentDialog;
    },
    // total all dialogs unread counter
    totalUnreadCounter: function () {
      let total = 0;
      for (const val of Object.values(this.dialogList)) {
        total = total + val.unread_messages_count;
      }
      return total;
    },
  },
  watch: {
    "$store.getters.getQbSession"() {
      qbHelper.getDialogs();
      QB.chat.onMessageListener = this.onMessageListener.bind(this);
      QB.chat.onReadStatusListener = this.onReadStatusListener.bind(this);
    },
    // get Message when there's an active dialog
    currentDialog(dialog) {
      if (dialog) {
        qbHelper.getMessages(dialog._id);
      }
    },

    totalUnreadCounter(val) {
      // pattern regex for : (n)
      const pattern = /^\(\d+\)/;

      if (pattern.test(document.title)) {
        document.title = document.title.replace(
          pattern,
          val > 0 ? `(${val})` : ""
        );
      } else {
        document.title = `(${val}) ${document.title}`;
      }
    },
  },
  mounted() {
    this.$api.firebase.subscribeFCM();
    this.qbCheckSession();
  },
  methods: {
    // If QB doesn't have session > create QB session
    qbCheckSession() {
      if (
        this.merchantData &&
        this.merchantData.quickblox_id &&
        Object.keys(this.dialogList).length === 0
      )
        qbHelper.createSession();
    },
    // listener for new message
    async onMessageListener(userId, message) {
      if (userId === this.merchantData.quickblox_id) return false;

      message = await qbHelper.fillNewMessageParams(userId, message);

      if (message.markable) qbHelper.sendDeliveredStatus(message, userId);

      if (this.dialogList[message.chat_dialog_id]) {
        // Update existing dialog
        this.$store.dispatch("setDialogParams", message);
      } else {
        // get all dialogs if dialog not found (new dialog)
        qbHelper.getDialogs();
      }

      if (message.chat_dialog_id === this.currentDialog._id) {
        const newMessage = await qbHelper.setMessages(message);
        this.$store.dispatch("pushNewMessage", newMessage);
        setTimeout(() => {
          qbHelper.scrollTo(document.querySelector(".j-messages"), "bottom");
        }, 200);
      }

      // TODO foreground notification lebih baik pake onMessage FCM
      // if browser tab is active show foreground notification
      if (!document.hidden) {
        this.showNotification(message);
      }
    },

    clickNotification(message) {
      if (this.$route.path !== "/livechat") this.$router.push("/livechat");
      // Auto select the new dialog
      this.$store.dispatch(
        "setCurrentDialog",
        this.dialogList[message.chat_dialog_id]
      );
    },

    showNotification(message) {
      // get sender name for message for new dialog
      qbHelper.getUserList(
        {
          field: "id",
          value: message.sender_id,
        },
        (res) => {
          if (res) {
            // if dialog not exist use res[0].full_name for the sender name
            const senderName = this.dialogList[message.chat_dialog_id]
              ? this.dialogList[message.chat_dialog_id].name
              : res[0].full_name;

            // Play audio for foreground chat notification
            const audio = new Audio(require("@/assets/audio/notification.mp3"));
            audio.play();

            const h = this.$createElement;
            // Custom title
            const vNodesTitle = h(
              "div",
              {
                class: [
                  "d-flex",
                  "flex-grow-1",
                  "justify-content-between",
                  "align-items-center",
                  "cursor-pointer",
                ],
                on: {
                  click: () => {
                    this.clickNotification(message);
                  },
                },
              },
              [
                h("strong", "New Message!"),
                h(
                  "small",
                  { class: "mr-2" },
                  this.formatTimestamp(message.date_sent)
                ),
              ]
            );

            // Custom message
            const vNodesMsg = h(
              "div",
              {
                class: ["d-flex", "flex-column", "cursor-pointer"],
                on: {
                  click: () => {
                    this.clickNotification(message);
                  },
                },
              },
              [
                h("strong", { class: "mb-1" }, senderName),
                h(
                  "div",
                  /WHIZPVL#/.test(message.message)
                    ? `Product: ${message.message.split("#")[3]}`
                    : /WHIZPVLO#/.test(message.message)
                    ? `Order: ${message.message.split("#")[1]}`
                    : message.message
                ),
              ]
            );

            toaster.make(vNodesMsg, "success", vNodesTitle);
          }
        }
      );
    },

    formatTimestamp(value) {
      return value ? moment.unix(value).format("HH:mm").toString() : value;
    },

    // TODO onReadStatusListener masih bug, cek sendReadStatus di website lawan chat nya
    onReadStatusListener(messageId, dialogId) {
      if (dialogId === this.currentDialog._id) {
        for (const messages of Object.values(this.messageList)) {
          messages.forEach((message) => {
            if (message._id === messageId) {
              this.$store.dispatch("setMessageStatus", message);
            }
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.container-fhd {
  display: contents;
}
@media (min-width: 768px) {
  .container-fhd {
    max-width: 1920px;
    display: block;
  }
}
.mw-32pt {
  max-width: 32pt;
}
.mh-0 {
  max-height: 0;
}
.mh-100 {
  max-height: 100%;
}
</style>
