export default () => ({
  vuelidateFormLogV2: (formData) => {
    formData.$touch()
    const error = formData.$error
    if (error)
      console.error(
        Object.keys(formData)
        .reduce((obj, el) => {
          if (!el.includes('$') && formData[el].$invalid)
            obj[el] = formData[el]
          return obj
        }, {})
      );
    return error
  },
  //TODO revamp code logic from using "map" & "filter" => using "reduce" function
  vuelidateFormLog: (formData) => {
    return console.error(
      Object.keys(formData)
      .reduce((obj, el) => {
        if (!el.includes('$') && formData[el].$invalid)
          obj[el] = formData[el]
        return obj
      }, {})
    );
  },
  //* FOR DEVELOPMENT PURPOSE
  //? FormData() log
  formData: (formData) => {
    let index = 1;
    console.log("--------INSPECT FORM DATA--------");
    for (const [key, value] of formData.entries()) {
      console.log(`%cForm Data ke-${index} => %ckey:${key} %cvalue:${(value)}`, "color:green; background:black; font-weight:bold", "color:yellow; background:black", "color:orange; background:black");
      index++;
    }
  }
})
