import firebase from "firebase/compat/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import store from "../store";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  const token = store.getters.getMerchantData.merchant_access_token
  if (token) headers.token = token
  return { headers }
}

export default axios => ({
  async _getTokenFCM() {
    try {
      if (!firebase.apps.length) {
        const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)
        firebase.initializeApp(firebaseConfig);
      }
      if (isSupported()) {
        const messaging = getMessaging();
        const token = await getToken(messaging, {
          vapidKey: process.env.VUE_APP_VAPID_KEY,
        });
        return token;
      }
    } catch (error) {
      return error;
    }
  },
  _getSelfQBTopic() {
    const quickbloxId = store.getters?.getMerchantData?.quickblox_id;
    if (quickbloxId) {
      return `${this._getQBTopic(quickbloxId)}`
    }
    return null;
  },
  _getQBTopic(id) {
    if (id) {
      return `qb-${id}`
    }
    return null;
  },
  async subscribeFCM() {
    try {
      const token = await this._getTokenFCM();
      const topic = this._getSelfQBTopic();
      if (topic) {
        const res = await axios.post(`${process.env.VUE_APP_API_URL}support/fcm/subscribe`, {
          topic, token,
        }, headers());
        if (res.status === 200) {
          console.log("Subscribe FCM success.");
        }
      }
    } catch (e) {
      console.error("Unable to get permission for notification.", e)
    }
  },
  async unsubscribeFCM() {
    try {
      const topic = this._getSelfQBTopic();
      if (topic) {
        const token = await this._getTokenFCM();
        const res = await axios.post(`${process.env.VUE_APP_API_URL}support/fcm/unsubscribe`, {
          topic, token,
        }, headers());
        if (res.status === 200) {
          console.log("Unsubscribe FCM success.");
        }
      }
    } catch (e) {
      console.error("Unable to unsubscribe FCM.", e)
    }
  },
  sendFCM(messageField) {
    try {
      const currentDialog = store.getters.getCurrentDialog
      const merchantName = store.getters.getMerchantData.merchant_full_name
      const opponentQbId = currentDialog.occupants_ids.filter(el => el != store.getters.getMerchantData.quickblox_id);
      let topic = null, list_topic = null;
      if (opponentQbId.length == 1) {
        topic = this._getQBTopic(opponentQbId[0]);
      } else {
        list_topic = opponentQbId.map(id => this._getQBTopic(id));
      }
      axios.post(`${process.env.VUE_APP_API_URL}merchants/fcm/send`,
        {
          topic,
          list_topic,
          title: merchantName,
          body: messageField,
          screenMobile: "chat/list-chat", // ? @click mobile apps version
          screenWeb: `https://${process.env.VUE_APP_API_URL.includes('staging') ? 'staging.' : ''}whizliz.com/`, // ? @click web version
          click_action: '',
        },
        headers(),
      );
    } catch (e) {
      console.error(e)
    }
  }

})
