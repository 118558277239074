import Vue from "vue";
import axios from "axios";
import User from "@/api/user";
import CMS from "@/api/cms";
import PickupPointApi from "@/api/pickuppoint";
import Brand from "@/api/brand";
import Bundle from "@/api/bundle";
import Product from "@/api/product";
import Order from "@/api/order";
import report from "@/api/report";
import Firebase from "@/api/firebase";
import Collection from "@/api/collection";
import media from "@/api/media";
import store from "./store";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  const token = store.getters.getMerchantData.merchant_access_token
  if (token) headers.token = token
  return { headers }
}

const factories = {
  user: User(axios),
  cms: CMS(axios),
  pickuppoint: PickupPointApi(axios),
  brand: Brand(axios),
  bundle: Bundle(axios),
  product: Product(axios),
  order: Order(axios),
  report: report(axios),
  firebase: Firebase(axios),
  collection: Collection(axios),
  media: media(axios, headers),
}

Vue.prototype.$api = factories
