import store from "../store";
import firebase from "./firebase"
import { cookieMan, qbHelper } from "@/_helpers";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  const token = store.getters.getMerchantData ? store.getters.getMerchantData.merchant_access_token : null
  if (token) headers.token = token
  return { headers }
}

const setCookie = (obj) => {
  for (let o in obj) {
    cookieMan.put(o, obj[o] || "")
  }
}

export default axios => ({
  async login(email, password) {
    try {
      const res = await axios.post(process.env.VUE_APP_API_URL + 'merchants/session/login', {
        email: email,
        password: password,
        device_model: "WEB",
        device_type: "WEB",
        device_id: "",
      }, headers())
      const d = res.data
      const retval = {
        quickblox_token: d.data.quickblox_token,
        quickblox_id: d.data.profile_data.quickblox_id,
        merchant_id: d.data.profile_data.id,
        merchant_access_token: d.data.access_token,
        merchant_expired_date: d.data.expired_date,
        merchant_first_name: d.data.profile_data.first_name,
        merchant_last_name: d.data.profile_data.last_name,
        merchant_full_name: d.data.profile_data.full_name,
        merchant_email: d.data.profile_data.email,
        merchant_provider: "email",
        merchant_type: d.data.profile_data.merchant_type,
        merchant_account_alias: d.data.profile_data.account_alias,
        merchant_account_number: d.data.profile_data.account_number,
        merchant_url: d.data.profile_data.url,
        merchant_phone: d.data.profile_data.phone,
        merchant_couriers: JSON.stringify(d.data.profile_data.couriers.map(el => ({shipping_id: el.id}))),
        merchant_address: d.data.profile_data.address,
        merchant_region: JSON.stringify(d.data.profile_data.region),
        merchant_district: JSON.stringify(d.data.profile_data.district),
        merchant_township: JSON.stringify(d.data.profile_data.township),
        merchant_postal: d.data.profile_data.postal_code,
        // merchant close store feature
        merchant_closed_notes: d.data.profile_data.closed_notes,
        merchant_start_date_close: d.data.profile_data.start_date_close_epoch,
        merchant_end_date_close: d.data.profile_data.end_date_close_epoch,
        // merchant pick up point platform
        merchant_pick_up_point_platform: JSON.stringify(d.data.profile_data.pickUpPointPlatform),
      }
      setCookie(retval)
      // set initial state for vuex store because the data type is object / array (in retval the value for the data is JSON stringfy)
      retval['merchant_couriers'] = d.data.profile_data.couriers.map(el => ({shipping_id: el.id}))
      retval['merchant_region'] =  d.data.profile_data.region
      retval['merchant_district'] =  d.data.profile_data.district
      retval['merchant_township'] =  d.data.profile_data.township
      retval['merchant_pick_up_point_platform'] = d.data.profile_data.pickUpPointPlatform,
      store.dispatch("setMerchantData", retval)
      return res
    } catch (e) {
      return e.response
    }
  },
  async logout() {
    await firebase(axios).unsubscribeFCM() // Unsub FCM topic
    cookieMan.remove("quickblox_token")
    cookieMan.remove("quickblox_id")
    cookieMan.remove("merchant_id")
    cookieMan.remove("merchant_access_token")
    cookieMan.remove("merchant_expired_date")
    cookieMan.remove("merchant_first_name")
    cookieMan.remove("merchant_last_name")
    cookieMan.remove("merchant_full_name")
    cookieMan.remove("merchant_email")
    cookieMan.remove("merchant_provider")
    cookieMan.remove("merchant_type")
    cookieMan.remove("merchant_account_alias")
    cookieMan.remove("merchant_account_number")
    cookieMan.remove("merchant_url")
    cookieMan.remove("merchant_phone")
    cookieMan.remove("merchant_couriers")
    cookieMan.remove("merchant_address")
    cookieMan.remove("merchant_region")
    cookieMan.remove("merchant_district")
    cookieMan.remove("merchant_township")
    cookieMan.remove("merchant_postal")
    store.dispatch("setMerchantData")
    qbHelper.clearQb()
  },
  async activateAccount(code) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/session/activate', {code}, headers())
    } catch (e) {
      return e.response
    }
  },
  async forgotPassword(email) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + 'merchants/session/forgot_password', {email}, headers())
    } catch (e) {
      return e.response
    }
  },
  async resetPassword(body) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/session/reset_password', body, headers())
    } catch (e) {
      return e.response
    }
  },
  async getProfile() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/session/profile', headers())
    } catch (e) {
      return e.response
    }
  },
  async getDashboard() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/dashboard', headers())
    } catch (e) {
      return e.response
    }
  },
  async getNotifications() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/notification?limit=200&offset=0&type=false', headers())
    } catch (e) {
      return e.response
    }
  },
  async readNotification(id) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/notification/' + id, {}, headers())
    } catch (e) {
      return e.response
    }
  },
  async readAllNotifications() {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/notification/read_all', {}, headers())
    } catch (e) {
      return e.response
    }
  },
  async updateProfile(body) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/session/update', body, headers())
    } catch (e) {
      return e.response
    }
  },
  async changePassword(body) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/session/change_password', body, headers())
    } catch (e) {
      return e.response
    }
  },
  async updateStore(body) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/session/update_closestore', body, headers())
    } catch (e) {
      return e.response;
    }
  },
  async getOutlets() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'external/order/my_outlet', headers())
    } catch (e) {
      return e.response;
    }
  },
  async getOperationalTime() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/cms/merchant/op_time", {
        params: {
          qb_id: store.getters.getMerchantData.quickblox_id,
          merchant_id: store.getters.getMerchantData.merchant_id,
        }, ...headers()
      })
    } catch (e) {
      return e.response;
    }
  },
  async updateOperationalTime(datetime) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + "merchants/session/update_operationaltime", datetime, headers())
    } catch (e) {
      return e.response;
    }
  },
})
