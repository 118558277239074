<template>
  <header>
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="dark"
      class="pl-0 justify-content-start sticky-top bg-black"
    >
      <b-navbar-toggle
        target="dashboard-sidebar"
        v-b-toggle.dashboard-sidebar
      />
      <b-navbar-brand to="/">
        <b-img
          :src="require('@/assets/img/logotype_white_background.svg')"
          alt="Whizliz"
          width="120"
          class="invert ml-lg-3"
        />
      </b-navbar-brand>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto align-items-center">
          <b-nav-item :href="`https://${apiUrl.indexOf('staging') < 0 ? 'cdn.whizliz.com' : 'd2i22acx3qywsx.cloudfront.net'}/guide/User Guide - WHIZLIZ - Merchant.pdf`" target="_blank">
            <button type="button" class="btn btn-wz-gold-dark">
              <font-awesome icon="download" size="lg" /> Merchant Guide
            </button>
          </b-nav-item>
          <b-nav-item to="/livechat">
            <button
              type="button"
              @click="contactCS"
              class="btn btn-seller-help px-3"
            >
              Contact CS
            </button>
          </b-nav-item>
          <b-nav-item to="/faq">
            <button type="button" class="btn btn-seller-help px-3">
              Seller Help
            </button>
          </b-nav-item>
          <!-- Notification -->
          <b-nav-item-dropdown class="notifications" right no-caret menu-class="pt-0">
            <template #button-content>
              <div class="d-flex align-items-center">
                <i class="icon-alarm"></i>
                <div
                  v-if="notifications.length > 0"
                  class="
                    position-absolute
                    bottom-0
                    end-0
                    badge
                    bg-danger
                    rounded-pill
                    text-white
                    montserrat
                  "
                >
                  {{ notifications.length }}
                </div>
              </div>
            </template>
            <b-dropdown-item v-if="notifications.length" link-class="px-2 bg-white text-right cursor-default">
              <small>
                <b>
                  <a href="#" class="text-info" @click="readAllNotifications">Read all notifications</a>
                </b>
              </small>
            </b-dropdown-item>
            <b-dropdown-item
              v-for="(noti, i) in notifications"
              :key="i"
              link-class="px-2"
              @click="readNotification(noti, i)"
            >
              <div class="d-flex align-items-center">
                <img src="@/assets/img/icon-info.png" />
                <div class="pl-2">
                  <b>{{ noti.title }}</b
                  ><br />
                  <small>{{ noti.content }}</small>
                </div>
              </div>
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <!-- Profile -->
          <b-nav-item-dropdown right no-caret>
            <template #button-content>
              <div class="d-flex align-items-center">
                <i class="icon-user"></i>
                <div class="d-none d-lg-inline-block px-1 pt-1">
                  {{ merchantData.merchant_full_name }}
                </div>
                <font-awesome icon="caret-down" />
              </div>
            </template>
            <b-dropdown-item to="/my-profile"
              >Manage my account</b-dropdown-item
            >
            <b-dropdown-item to="/change-password"
              >Change password</b-dropdown-item
            >
            <b-dropdown-item to="/logout">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-sidebar id="dashboard-sidebar" backdrop>
      <Sidebar />
    </b-sidebar>
  </header>
</template>
<script>
import Sidebar from "@/components/navs/Sidebar";

import { toaster, qbHelper } from "@/_helpers";

export default {
  name: "Header",
  components: {
    Sidebar,
  },
  data() {
    return {
      notifications: [],
    };
  },
  computed: {
    merchantData() {
      return this.$store.getters.getMerchantData;
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    }
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    async getNotifications() {
      try {
        const res = await this.$api.user.getNotifications();
        if (res.status === 200) this.notifications = res.data.data;
        else toaster.make("Something went wrong", "danger");
      } catch (e) {
        console.error(e);
      }
    },
    async readNotification(noti, index) {
      try {
        const res = await this.$api.user.readNotification(noti.id);
        if (res.status === 200) {
          this.notifications.splice(index, 1);
          if (noti.title == "New Order") this.$router.push("order-list?t=new");
        } else toaster.make("Something went wrong", "danger");
      } catch (e) {
        console.error(e);
      }
    },
    async readAllNotifications() {
      try {
        const res = await this.$api.user.readAllNotifications();
        if (res.status === 200) {
          this.notifications = []
          toaster.make('Notifications cleared', 'success')
        } else toaster.make('Something went wrong', 'danger')
      } catch (e) {
        toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    contactCS() {
      // CS Staging : 130193959 (wbgenshin@gmail.com , CUMQJJ)
      // CS Production : 130366388
      const csQbId = /staging/.test(process.env.VUE_APP_API_URL)
        ? 130193959
        : 130366388;
      qbHelper.createDialog(csQbId);
    },
  },
};
</script>
<style scoped>
.btn-seller-help {
  border: 1px solid white;
  background: #888;
  color: white;
  font-size: 0.765rem;
  transition: 0.5s all;
}
.btn-seller-help:hover {
  border: 1px solid #d89700;
  background: #d89700;
  color: white;
}
@media (min-width: 992px) {
  header > nav.navbar {
    min-height: 100px;
  }
}
</style>
