import store from "../store";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType,
    token: store.getters.getMerchantData.merchant_access_token,
  }
  return {
    headers
  }
}

export default axios => ({
  async getOrderReport(period, period2, offset, limit) {
    try {
      const obj = {offset, limit}
      if (period) obj.period = period
      if (period2) obj.period2 = period2
      const queryString = []
      for (const o in obj) {
        queryString.push(o + '=' + obj[o])
      }
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/report/order?' + queryString.join('&'), headers())
    } catch (e) {
      return e.response
    }
  },
  async getTopSales(period, period2, offset, limit) {
    try {
      const obj = {offset, limit}
      if (period) obj.period = period
      if (period2) obj.period2 = period2
      const queryString = []
      for (const o in obj) {
        queryString.push(o + '=' + obj[o])
      }
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/report/top_sales?' + queryString.join('&'), headers())
    } catch (e) {
      return e.response
    }
  },
})
