import store from "../store";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  const token = store.getters.getMerchantData.merchant_access_token
  if (token) headers.token = token
  return { headers }
}

export default axios => ({
  async saveCollection(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + 'merchants/productcollection', data, headers('multipart/form-data'))
    } catch (e) {
      return e.response
    }
  },
  async updateCollection(data, collectionId) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + `merchants/productcollection/${collectionId}`, data, headers())
    } catch (e) {
      return e.response
    }
  },
  async getCollectionByBrand(brandId) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `merchants/productcollection/${brandId}`, headers())
    } catch (e) {
      return e.response
    }
  },
  async getCollectionDetail(collectionId) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `merchants/productcollection/${collectionId}/detail`, headers())
    } catch (e) {
      return e.response
    }
  },
  async deleteCollection(collectionId) {
    try {
      return await axios.delete(process.env.VUE_APP_API_URL + `merchants/productcollection/${collectionId}`, headers())
    } catch (e) {
      return e.response      
    }
  },
})