export default (axios, headers) => ({
  async upload(file, path) {
    try {
      const form = new FormData()
      form.append('file', file)
      form.append('path', path)
      return await axios.post(process.env.VUE_APP_API_URL + 'merchants/media', form, {
        ...headers('multipart/form-data'),
      })
    } catch (e) {
      return e.response
    }
  }  
})
