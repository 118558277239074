<template>
  <footer class="p-4">
    &copy; {{ new Date().getFullYear() }} Whizliz.com. All rights reserved.
  </footer>
</template>
<script>
export default {
  name: "Footer"
}
</script>
