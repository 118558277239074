<template>
  <div v-if="!connected" class="login">
    <img src="@/assets/img/logotype_white_background.svg" width="250" />
  </div>
  <router-view v-else />
</template>
<script>
import { toaster, qbHelper } from "@/_helpers";

export default {
  name: "MainApp",
  data() {
    return {
      connected: false,
    };
  },
  async mounted() {
    try {
      const res = await this.$api.user.login();
      if (res) {
        setTimeout(() => this.connected = true, 2000);
        qbHelper.initQuickblox();
      } else
        toaster.make(
          "Please try again later",
          "danger",
          "Connection failed"
        );
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
