import Vue from 'vue'
import VueRouter from 'vue-router'
import Content from '../views/Content'
import store from '../store'

Vue.use(VueRouter)

// const prefixRoutes = (prefix, routes) => {
//   return routes.map(route => {
//     route.path = prefix + '/' + route.path
//     return route
//   })
// }

const routes = [
  {
    path: '/',
    name: 'Content',
    component: Content,
    children: [
      {path: '', component: () => import('../views/pages/Dashboard')},
      {path: 'faq', component: () => import('../views/pages/FAQ')},
      {path: 'faq/:slug', component: () => import('../views/pages/FAQ')},
      {path: 'my-profile', component: () => import('../views/pages/settings/AccountInfo')},
      {path: 'manage-store', component: () => import('../views/pages/settings/ManageStore')},
      {path: 'change-password', component: () => import('../views/pages/settings/ChangePassword')},
      {path: 'manage-brand', component: () => import('../views/pages/brand/BrandList')},
      {path: 'edit-brand/:id', component: () => import('../views/pages/brand/EditBrand')},
      {path: 'manage-bundle', component: () => import('../views/pages/bundle/BundleList')},
      {path: 'add-bundle', component: () => import('../views/pages/shared/SaveProduct')},
      {path: 'edit-bundle/:id', component: () => import('../views/pages/shared/SaveProduct')},
      {path: 'detail-bundle/:id', component: () => import('../views/pages/shared/SaveProduct')},
      {path: 'manage-product', component: () => import('../views/pages/product/ProductList')},
      {path: 'add-product', component: () => import('../views/pages/shared/SaveProduct')},
      {path: 'edit-product/:id', component: () => import('../views/pages/shared/SaveProduct')},
      {path: 'detail-product/:id', component: () => import('../views/pages/shared/SaveProduct')},
      {path: 'review-product', component: () => import('../views/pages/product/ProductReview')},
      {path: 'manage-product-variance/:id', component: () => import('../views/pages/productVariance/ProductVariance')},
      {path: 'manage-size-guide', component: () => import('../views/pages/product/SizeGuideList')},
      {path: 'add-size-guide', component: () => import('../views/pages/product/SaveSizeGuide')},
      {path: 'add-collection', component: () => import('../views/pages/collection/SaveCollection')},
      {path: 'edit-collection/:id', component: () => import('../views/pages/collection/SaveCollection')},
      {path: 'detail-collection/:id', component: () => import('../views/pages/collection/SaveCollection')},
      {path: 'manage-collection', component: () => import('../views/pages/collection/ManageCollection')},
      {path: 'list-pickup', component: () => import('../views/pages/pickupPoint/PickupPointPage')},
      {path: 'add-pickup', component: () => import('../views/pages/pickupPoint/SavePickupPoint')},
      {path: 'edit-pickup/:id', component: () => import('../views/pages/pickupPoint/SavePickupPoint')},
      {path: 'order-report', component: () => import('../views/pages/ReportList')},
      {path: 'top-sales-report', component: () => import('../views/pages/ReportList')},
      {path: 'order-list', component: () => import('../views/pages/OrderList')},
      {path: 'late-order', component: () => import('../views/pages/OrderList')},
      {path: 'livechat', component: () => import('../views/pages/Livechat')},
      {path: 'reload', component: () => import('../views/pages/shared/Reload')},
    ],
    beforeEnter(to, from, next) {
      if (store.getters.getMerchantData) next()
      else next('/login')
    }
  },
  {
    path: '/packing-slip/:id',
    name: 'PackingSlip',
    component: () => import('../views/pages/PackingSlip'),
    beforeEnter(to, from, next) {
      if (store.getters.getMerchantData) next()
      else next('/login')
    }
  },
  {
    path: '/reset-password/:key',
    name: 'ResetPassword',
    component: () => import('../views/Login'),
    beforeEnter(to, from, next) {
      if (store.getters.getMerchantData) next('/')
      else next()
    }
  },
  {
    path: '/activate/:id',
    name: 'Activation',
    component: () => import('../views/Login'),
    beforeEnter(to, from, next) {
      if (store.getters.getMerchantData) next('/')
      else next()
    }
  },
  {
    path: '/logout',
    redirect: '/login',
    component: () => import("../views/Login"),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("../views/Login"),
  },
  {
    path: '*',
    redirect: '/',
    component: Content,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
