import moment from "moment";

export default () => ({
    //? price
    // * IDR XX.XXX.XXX,XX
    price: (value = "", kode = "Rp ", separator = '.') =>
        value ? kode + value.toString().replace(/(\d{1,3})(?=(\d{3})+(?!\d))/g, `$1${separator}`) : value,
    // price: (value, kode = "IDR ", maximumFractionDigits = 2) =>
    //     value ? kode + this.thousands(value, maximumFractionDigits) : value,
    //? thousands separator
    // * XX.XXX.XXX,XX
    thousands: (value = "", separator = '.') =>
        value?.toString().replace(/(\d{1,3})(?=(\d{3})+(?!\d))/g, `$1${separator}`),
    localeThousand: (value = "", maximumFraction = 2, locale = 'id') => value?.toLocaleString(locale, { maximumFractionDigits: maximumFraction }),
    //? date
    // * 1 May 2020
    date: (value, format = "D MMM YYYY") =>
        value ? moment(value).format(format) : value,
    dateAPI: (value, format = "YYYY-MM-DD HH:mm:ssZ") =>
        value ? moment(value).format(format) : value,
    dateAPIGet: (value, format = "YYYY-MM-DD") =>
        value ? moment(value).format(format) : value,
    // * Epoch milliseconds to local time = 1 May 2020
    epochMsToLocalTime: (value, format = "D MMM YYYY") =>
        value ? moment.unix(value / 1000).format(format) : value,
    slug: (value) =>
        value.toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, ''),
})