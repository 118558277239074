import store from "../store";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  const token = store.getters.getMerchantData.merchant_access_token
  if (token) headers.token = token
  return { headers }
}

export default axios => ({
  // start of product management req
  async getCategories() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/categories', headers())
    } catch (e) {
      return e.response
    }
  },
  async getBrands() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/cms/all_brand', headers())
    } catch (e) {
      return e.response
    }
  },
  async getAllBrands() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/brand/all', headers())
    } catch (e) {
      return e.response
    }
  },
  // end of product management req
  async getCourierList() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/couriers', headers())
    } catch (e) {
      return e.response
    }
  },
  async getProvinceList() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/state/region', headers())
    } catch (e) {
      return e.response
    }
  },
  async getDistrictList(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/state/province/' + id, headers())
    } catch (e) {
      return e.response
    }
  },
  async getSubDistrictList(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/state/city/' + id, headers())
    } catch (e) {
      return e.response
    }
  },
  async getColors() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/color', headers())
    } catch (e) {
      return e.response
    }
  },
  async getSizes() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/size', headers())
    } catch (e) {
      return e.response
    }
  },
  async getFaqs(slug) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/cms/faq' + (slug ? '/' + slug : ''), headers())
    } catch (e) {
      return e.response
    }
  },
})
