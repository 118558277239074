import Vue from "vue";
import format from "@/helpers/format";
// import report from "@/helpers/report";
// import convert from "@/helpers/convert";
// import download from "@/helpers/download";
import toaster from "@/helpers/toaster";
import console from "@/helpers/console";
// import other from "@/helpers/other";
// import assistant from "@/helpers/assistant";

const helpers = {
    format: format(),
    // report: report(),
    // convert: convert(),
    // download: download(),
    toaster: toaster(),
    console: console(),
    // other: other(),
    // assistant: assistant(),
}

Vue.prototype.$helpers = helpers