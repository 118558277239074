import Vue from 'vue'
// import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './api.js'
import './helpers.js'
import './registerServiceWorker'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons' // semua di import
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)

import { Line } from "vue-chartjs";
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueCropper from 'vue-cropperjs'
import Vuelidate from 'vuelidate'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
import 'cropperjs/dist/cropper.min.css'
import './assets/css/all.css'


Vue.component('font-awesome', FontAwesomeIcon)
Vue.component('fawesome-pro', () => import('./components/FAwesomeMaker'))
Vue.component('line-chart', {
  extends: Line,
  props: ["lineChartData"],
  mounted () {
    this.renderChart(this.lineChartData, {responsive: true, maintainAspectRatio: false})
  }
})
Vue.component('vue-cropper', VueCropper)
Vue.component('vue-ckedit', () => import('@/components/ckeditor/CKEditor'))

// Vue.use(axios)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(CKEditor)
Vue.use(Vuelidate)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
