import Vue from 'vue'
import Vuex from 'vuex'
import { cookieMan } from "../_helpers";

Vue.use(Vuex)

const merchantData = () => {
  const merchantId = cookieMan.get("merchant_id")
  try {
    return !merchantId ? null : {
      quickblox_token: cookieMan.get("quickblox_token"),
      quickblox_id: parseInt(cookieMan.get("quickblox_id")),
      merchant_id: merchantId,
      merchant_access_token: cookieMan.get("merchant_access_token"),
      merchant_expired_date: cookieMan.get("merchant_expired_date"),
      merchant_first_name: cookieMan.get("merchant_first_name"),
      merchant_last_name: cookieMan.get("merchant_last_name"),
      merchant_full_name: cookieMan.get("merchant_full_name"),
      merchant_email: cookieMan.get("merchant_email"),
      merchant_provider: cookieMan.get("merchant_provider"),
      merchant_type: cookieMan.get("merchant_type"),
      merchant_account_alias: cookieMan.get("merchant_account_alias"),
      merchant_account_number: cookieMan.get("merchant_account_number"),
      merchant_url: cookieMan.get("merchant_url"),
      merchant_phone: cookieMan.get("merchant_phone"),
      merchant_gender: cookieMan.get("merchant_gender"),
      merchant_couriers: JSON.parse(cookieMan.get("merchant_couriers")),
      merchant_address: cookieMan.get("merchant_address"),
      merchant_region: JSON.parse(cookieMan.get("merchant_region")),
      merchant_district: JSON.parse(cookieMan.get("merchant_district")),
      merchant_township: JSON.parse(cookieMan.get("merchant_township")),
      merchant_postal: cookieMan.get("merchant_postal"),
      // merchant close store feature
      merchant_closed_notes: cookieMan.get("merchant_closed_notes"),
      merchant_start_date_close: cookieMan.get("merchant_start_date_close"),
      merchant_end_date_close: cookieMan.get("merchant_end_date_close"),
      // merchant pick up point platform
      merchant_pick_up_point_platform: JSON.parse(cookieMan.get('merchant_pick_up_point_platform')),
    }
  } catch (e) {
    console.error(e);
    return
  }
}

const isLoading = () => {
  return {
    dialogList: true,
    messageList: true,
  }
}

export default new Vuex.Store({
  state: {
    merchantData: merchantData(),
    editBrand: null,
    qbSession: false,
    dialogList : {},
    currentDialog: {}, 
    messageList : {},
    isLoading: isLoading(),
    deviceToken: "",
  },
  mutations: {
    setMerchantData (state, payload) {
      state.merchantData = payload
    },
    setEditBrand (state, payload) {
      state.editBrand = payload
    },
    updateMerchantData (state, {key, value}) {
      state.merchantData[key] = value
    },
    // for quickblox START
    setQbSession (state, payload) {
      state.qbSession = payload
    },
    setDialogList (state, payload) {
      state.dialogList = payload
    },
    setCurrentDialog (state, payload) {
      state.currentDialog = payload
    },
    setDialogParams (state, payload) {
      state.dialogList = Object.assign(payload, state.dialogList);
    },
    pushNewDialog (state, dialog) {
      state.dialogList = Object.assign({[dialog._id]: dialog}, state.dialogList);
    },
    decreaseUnreadCounter (state, dialogId) {
      state.dialogList[dialogId].unread_messages_count = 0
    },
    setMessageList (state, payload) {
      state.messageList = payload
    },
    setIsLoading (state, {key, value}) {
      state.isLoading[key] = value
    },
    pushNewMessage (state, message) {
      state.messageList[message.dateIds].push(message)
    },
    setMessageStatus (state, message) {
      state.messageList[message.dateIds].find(el => 
        el._id === message._id
      ).status = "read"; 
    },
    // for quickblox END
    setDeviceToken (state, payload) {
      state.deviceToken = payload
    },
  },
  actions: {
    setMerchantData ({commit}, data) {
      commit("setMerchantData", data)
    },
    setEditBrand({commit}, data) {
      commit("setEditBrand", data)
    },
    updateMerchantData ({commit, getters}, {key, value}) {
      const merchantData = getters.getMerchantData
      // If merchant data property != with new value
      if(merchantData[key] != value) commit("updateMerchantData", {key, value})    
    },
    setQbSession ({commit}, data) {
      commit("setQbSession", data)
    },
    setDialogList ({commit}, data) {
      commit("setDialogList", data)
    },
    setCurrentDialog ({commit}, data) {
      commit("setCurrentDialog", data)
    },
    setDialogParams({commit,state}, message) {
      let tmpObj = {};
      tmpObj[message.chat_dialog_id] = state.dialogList[message.chat_dialog_id];
      tmpObj[message.chat_dialog_id].last_message = message.message;
      tmpObj[message.chat_dialog_id].last_message_date_sent = message.date_sent;
      tmpObj[message.chat_dialog_id].last_message_id = message._id;
      tmpObj[message.chat_dialog_id].last_message_user_id = message.sender_id;
      if (!message.selfReaded) {
        tmpObj[message.chat_dialog_id].unread_messages_count++;
      }
      delete state.dialogList[message.dialog_id];
      commit("setDialogParams", tmpObj)
    },
    pushNewDialog({commit}, dialog) {
      commit("pushNewDialog", dialog)
    },
    decreaseUnreadCounter({commit}, dialogId) {
      commit("decreaseUnreadCounter", dialogId)
    },
    setMessageList ({commit}, data) {
      commit("setMessageList", data)
    },
    setIsLoading ({commit}, {key, value}) {
      commit("setIsLoading", {key, value})
    },
    pushNewMessage ({commit,state}, messages) {
      for (var key in messages) {
        // For new date property "DD/MM/YYYY" if doesn't exist
        if(state.messageList[key] === undefined) {
          state.messageList = { ...state.messageList, [key]: new Array() }
        }
      }
      // Object.keys(state.messageList).forEach(el => {
      //   state.messageList[el] = state.messageList[el].filter(rel => rel)
      // })
      commit("pushNewMessage", messages[key][0])
    },
    setMessageStatus ({commit}, message) {
      commit("setMessageStatus", message)
    },
    setDeviceToken ({commit}, data) {
      commit("setDeviceToken", data)
    },
  },
  getters: {
    getMerchantData: (state) => state.merchantData, 
    getEditBrand: (state) => state.editBrand, 
    getQbSession: (state) => state.qbSession,
    getDialogList: (state) => state.dialogList,
    getCurrentDialog: (state) => state.currentDialog,
    getMessageList: (state) => state.messageList,
    getIsLoading: (state) => state.isLoading,
    getDeviceToken: (state) => state.deviceToken,
  }
})
