import store from "../store";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType,
  }
  const token = store.getters.getMerchantData.merchant_access_token
  if (token) headers.token = token
  return { headers }
}

export default axios => ({
  async addHighlight(brandId, formData) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + 'merchants/brand/' + brandId + '/add_highlight', formData, headers("multipart/form-data"))
    } catch (e) {
      return e.response
    }
  },
  async editHighlight(brandId, formData) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + 'merchants/brand/' + brandId + '/edit_highlight', formData, headers("multipart/form-data"))
    } catch (e) {
      return e.response
    }
  },
  async getHighlights(brandId) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'merchants/brand/' + brandId + '/list_highlight', headers())
    } catch (e) {
      return e.response
    }
  },
  async editBrand(brandId, formData) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + `merchants/brand/${brandId}`, formData, headers("multipart/form-data"))
    } catch (e) {
      return e.response
    }
  },
})
